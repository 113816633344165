<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    @close="close"
    :close-on-click-modal="false"
    width="30%"
  >
    <template v-if="view === 'notice' && selectedOrdersWithSp">
      <el-row type="flex">
        <el-col :span="24">
          <span class="font-base">
            <span class="text-bold">{{ selectedOrdersWithSp }}</span> of the
            orders you selected are currently assigned to a service partner.
            Will you like to go ahead to assign a new service partner to them?
          </span>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="close">Cancel</el-button>
        <el-button type="primary" @click="view = 'assign'"
          >Go ahead and assign</el-button
        >
      </span>
    </template>
    <template v-else>
      <el-form :model="form" label-position="top" ref="form">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="Service Partner"
              prop="sp"
              :rules="validateField()"
            >
              <el-select
                v-model="form.sp"
                placeholder="Select a service partner"
              >
                <el-option
                  v-for="(sp, index) in servicePartners"
                  :key="index"
                  :label="sp.name"
                  :value="sp.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="close">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="!form.sp"
          :loading="assigning"
          @click="assign"
          >Assign</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import laundry from "@/requests/orders/laundry";

export default {
  name: "LaundrySpAssignment",
  props: {
    show: {
      type: Boolean,
      default() {
        return {};
      },
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedOrdersWithSp: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ordersWithSp: [],
      view: "notice",
      form: {
        sp: "",
      },
      assigning: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.view === "notice"
        ? "Existing Service Partners Notice"
        : "Assign Service Partner";
    },
    servicePartners() {
      return this.$store.getters.service_partners_list.laundry;
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.form.sp = "";
      this.view = "notice";
    },
    assign() {
      this.assigning = true;
      const payload = {
        actor_id: this.$store.getters.user.logged_in_userId,
        order_ids: this.selectedOrders,
        sp_id: this.form.sp,
      };

      laundry
        .assignSp(payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.$message.success(message);
            this.$emit("success");
            this.close();
          } else {
            this.$message.error(message);
          }
          this.assigning = false;
        })
        .catch((error) => {
          this.assigning = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
