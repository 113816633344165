<template>
  <div>
    <eden-table-actions :title="title" @search="searchOrders">
      <template slot="title">
        <eden-periods class="ml-10" :loading="loading" :default-period="'today'" @set-period="getOrders"
          addCustomFilter />
      </template>
      <template slot="actions">
        <template v-if="selectedOrders.length">
          <laundry-pickup-actions v-show="showSelectedOrders" ref="laundryActions" :selected-orders="selectedOrders"
            @error="selectedOrders = []" @updated="getOrders({ period: period })" />
        </template>
        <template v-else>
          <eden-service-partners :allow-all="true" :service-partner-id.sync="servicePartnerId"
            :service-partner-name.sync="servicePartnerName" :service="'laundry'" />
          <el-button type="plain" icon="eden-icon-upload" :disabled="loading || !pageData.length"
            v-if="servicePartnerName !== 'all'" @click="showExport = true">
            Export
          </el-button>
        </template>
      </template>
    </eden-table-actions>

    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData" @select-all="setSelectedOrders" @selection-change="setSelectedOrders">
          <el-table-column v-if="allowAccessFor(['admin', 'operations'])" type="selection" width="45"></el-table-column>
          <el-table-column width="80">
            <template slot="header">
              <div class="table--header">
                <span>Order Id</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link :to="{
                name: 'orders.order',
                params: { id: scope.row.order_id },
              }">
                <span class="font-sm text-primary">
                  {{ scope.row.order_id }}</span></router-link>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Customer</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link :to="{
                name: 'customers.individual',
                params: { id: scope.row.customer_id },
              }">
                <span class="font-sm">
                  {{ formatName(scope.row.customer) }}</span></router-link>
              <span class="font-xsm text-grey-tertiary">
                {{ scope.row.gardener }}</span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Phone Number </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPhoneNumber(scope.row.customer_phone_number) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <div class="table--header">
                <span>Order Type </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatText(scope.row.order_type) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <div class="table--header">
                <span>Type </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm"> {{ unformatSlug(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Channel</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatChannel(scope.row.channel_id) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>No of Bags </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Items Tally </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">{{
                setTotalItemsTally(scope.row.items)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header">
              <div class="table--header">
                <span>Laundry Pickup Date </span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatDate(scope.row.pickup, "do m, y") }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Location/Area </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.locationarea }} ({{ scope.row.priority }})</span>
            </template>
          </el-table-column>
          <el-table-column width="250">
            <template slot="header">
              <div class="table--header">
                <span>Address </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm"> {{ scope.row.customer_address }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <span class="font-sm">Service Partner </span>
            </template>
            <template slot-scope="scope">
              <span class="font-sm"> {{ scope.row.sp }}</span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Ordered At</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag type="success" class="text-normalize">
                {{ formatDateAndTime(scope.row.created_at, "do m,y") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark" content=" This signifies that  an SP has been assigned to the order"
                  placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span>SP Assign Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'sp_assigned'"
                :function-status="!!scope.row.sp_assigned.status" :function-time="scope.row.sp_assigned.time"
                :function-value="scope.row.sp_assigned.sp" :function-actor="scope.row.sp_assigned.name"
                @action="setSelectedOrder(scope.row, 'sp_assigned')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark"
                  content=" This signifies that the rider is on his way to pick up laundry from the customer."
                  placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Rider En Route </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'rider_enroute'"
                :function-status="!!scope.row.rider_enroute.status" :function-time="scope.row.rider_enroute.time"
                :function-actor="scope.row.rider_enroute.name" @action="setSelectedOrder(scope.row, 'rider_enroute')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark"
                  content="This signifies that the rider has arrived at the customer’s pick up location." placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Rider Arrival Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowEventMarking(period, ['gardener'])"
                :mark-function="'pickup_rider_arrived'" :function-status="!!scope.row.pickup_rider_arrived.status"
                :function-time="scope.row.pickup_rider_arrived.time" :function-actor="scope.row.pickup_rider_arrived.name"
                @action="setSelectedOrder(scope.row, 'pickup_rider_arrived')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark" content=" This signifies that the rider has picked up laundry."
                  placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Pick Up Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'rider_pickup'"
                :function-status="!!scope.row.rider_pickup.status" :function-time="scope.row.rider_pickup.time"
                :function-actor="scope.row.rider_pickup.name" @action="setSelectedOrder(scope.row, 'rider_pickup')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark"
                  content="This signifies that the rider has delivered laundry at the office." placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Delivery Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking"
                :mark-function="'pickup_rider_delivered'" :function-status="!!scope.row.pickup_rider_delivered.status"
                :function-time="scope.row.pickup_rider_delivered.time"
                :function-actor="scope.row.pickup_rider_delivered.name"
                @action="setSelectedOrder(scope.row, 'pickup_rider_delivered')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark" content="This signifies that the laundry has been quality checked. "
                  placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Quality Check Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'pickup_qc'"
                :function-status="!!scope.row.pickup_qc.status" :function-time="scope.row.pickup_qc.time"
                :function-actor="scope.row.pickup_qc.name" @action="setSelectedOrder(scope.row, 'pickup_qc')" />
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <el-tooltip class="tip" effect="dark" content="This signifies that the laundry has been sent to the SP."
                  placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                <span> Sent to SP Status </span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'sent_to_sp'"
                :function-status="!!scope.row.sent_to_sp.status" :function-time="scope.row.sent_to_sp.time"
                :function-actor="scope.row.sent_to_sp.name" @action="setSelectedOrder(scope.row, 'sent_to_sp')" />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination v-if="showPagination" :from="from" :to="to" :total="total" :current-page.sync="page" />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
    <orders-schedule-laundry-pickup-export :show.sync="showExport" :period="period" :custom-date="custom" :data="exportData"
      :data-time="pageDataTime" :service-partner-id="servicePartnerId" />
  </div>
</template>
<script>
import OrderFunctionStatus from "@/components/Stewards/OrderFunctionStatus";
import LaundryPickupActions from "@/components/Orders/Schedule/Laundry/LaundryPickupActions";
import OrdersScheduleLaundryPickupExport from "@/components/Orders/Schedule/Laundry/Export/OrdersScheduleLaundryPickupExport";
import schedule from "@/requests/orders/schedule";
import * as actions from "@/store/action-types";
import { formatLaundryPickupData } from "@/components/Orders/Schedule/format-schedule-data";

export default {
  name: "OrdersScheduleLaundryPickup",
  components: {
    LaundryPickupActions,
    OrderFunctionStatus,
    OrdersScheduleLaundryPickupExport,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      searchQuery: "",
      selectedOrders: [],
      period: "today",
      servicePartnerId: "all",
      servicePartnerName: "",
      showExport: false,
      exportData: [],
      pageDataTime: "",
      showSelectedOrders: false,
      custom: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    orders() {
      return this.$store.getters.orders_schedule.laundry_pickup;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;

      return `${length} Order${length > 1 ? "s" : ""}`;
    },
    allowMarking() {
      return this.allowEventMarking(this.period, ["gardener", "gardener_pro"]);
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getOrders({
          period: this.period, from: this.custom.from,
          to: this.custom.to,
        });
      }
    },
    servicePartnerId() {
      this.getOrders({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
    searchQuery() {
      if (this.searchQuery !== "") {
        this.searchOrders();
      } else {
        this.setPageData();
      }
    },
  },
  created() {
    this.getOrders({
      period: this.period
    });
  },
  methods: {
    formatChannel(channel_id) {
      let name;
      switch (channel_id) {
        case 1:
          name = "Mobile app";
          break;

        case 2:
          name = "Green house";
          break;

        case 3:
          name = "Web app";
          break;

        default:
          name = "--";
      }
      return name;
    },
    getOrders({ period, from, to }) {
      this.selectedOrders = [];
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.custom.from = start_date;
      this.custom.to = end_date;
      this.loading = true;
      this.$store
        .dispatch(actions.GET_ORDERS_SCHEDULE, {
          service: "laundry_pickup",
          servicePartnerId: this.servicePartnerId,
          start_date: this.custom.from,
          end_date: this.custom.to,
          page: this.page,
        })
        .then(() => {
          this.period = period;
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchOrders(query) {
      if (!query) {
        this.loading = false;
        this.setPageData();
        return;
      }
      this.loading = true;
      const start_date = this.custom.from;
      const end_date = this.custom.to;
      schedule
        .search({
          servicePartnerId: this.servicePartnerId,
          service: "laundry_pickup",
          query: this.stringTrim(query),
          start_date,
          end_date,
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data ? formatLaundryPickupData(data) : [];
            this.exportData = this.pageData;
            this.showPagination = false;
          } else {
            this.$message.info("No customer order");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.orders.pages[this.page].data;
      this.pageData = formatLaundryPickupData(this.pageData);
      this.showPagination = true;
      this.pageDataTime = new Date();
    },

    setSearchQuery(query) {
      this.searchQuery = query;
    },

    setSelectedOrders(orders) {
      this.selectedOrders = orders;
      this.showSelectedOrders = !!orders.length;
    },
    setSelectedOrder(order, markFunction) {
      this.selectedOrders.push(order);
      setTimeout(() => {
        this.$refs.laundryActions.markFunction = markFunction;
        this.$refs.laundryActions.markOrder();
      }, 5);
    },

    setTotalItemsTally(items) {
      if (!items.length) {
        return 0;
      }
      return items.reduce((total, item) => {
        return total + Number(item.count);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  margin-right: 7px;
}

.el-table {
  .font-xsm.margin {
    margin: 0 10px;
  }
}
</style>
