import axios from "axios";

export default {
  pickupSchedule(servicePartnerId, period, page) {
    return axios.get(
      `${servicePartnerId}/customer_orders/laundry/zones?page=${page}&period=${period}`
    );
  },

  types() {
    return axios.get("laundry/items/all");
  },

  assignSp(payload) {
    return axios.post("crm/order/sp/assignment", payload);
  },

  tallyGet(id) {
    return axios.get(`customer_order/${id}/laundry_tally`);
  },

  tallyUpdate(id, payload) {
    return axios.post(`customer_order/${id}/laundry_tally`, payload);
  },

  qcReportGet(orderId, report_type) {
    return axios.get(
      `crm/customer/report/activity?order_id=${orderId}&report_type=${report_type}`
    );
  },

  qcReport(payload) {
    return axios.post("crm/customer/report/activity", payload);
  },

  qcReportUpdate(payload) {
    return axios.post("crm/customer/report/activity/update", payload);
  },
};
