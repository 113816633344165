<template>
  <div>
    <el-dialog title="Export" :visible.sync="setShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields" :max="form.format === 'pdf' ? 5 : 7">
                  <el-checkbox v-for="(value, key, i) in fields" :key="i" :label="key" :disabled="key === 'customer'">
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="exporting" :disabled="disabled" @click="getExportData">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import { formatLaundryPickupData } from "@/components/Orders/Schedule/format-schedule-data";
import schedule from "@/requests/orders/schedule";

export default {
  name: "OrderScheduleLaundryPickupExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    servicePartnerId: {
      type: [String, Number],
      default: null,
    },
    period: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    customDate: {
      type: Object,
      default() {
        return {};
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        order_id: "Order Id",
        customer: "Customer",
        type: "Service Type",
        order_type: "Order Type",
        customer_phone_number: "Phone Number",
        quantity: "No of Bags",
        item: "Items Tally",
        pickup: "Pickup Date",
        locationarea: "Location Area",
        customer_address: "Address",
        landmark: "Landmark",
        sp_assigned: "SP Assign Status",
        rider_enroute: "Rider En Route",
        pickup_rider_arrived: "Rider Arrival Status",
        rider_pickup: "Pick up Status",
        pickup_rider_delivered: "Delivery Status",
        pickup_qc: "QC Status",
        sent_to_sp: "Sent to SP Status",
        delivery_date: "Delivery Date",
        order_date: "Order Date"
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["customer"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabled() {
      return !this.form.format || !this.form.fields.length;
    },
  },
  methods: {
    closeEvent() {
      (this.form.fields = ["customer"]), (this.form.format = "");
      this.exporting = false;
      this.setShow = false;
    },

    getExportData() {
      this.exporting = true;
      if (this.data.length) {
        this.formatExportData(this.data);
        return;
      }
      schedule
        .export({
          servicePartnerId: this.servicePartnerId,
          period: this.period,
          start_date: this.customDate.from,
          end_date: this.customDate.to
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            console.log(data);
            let formattedLaundryData = formatLaundryPickupData(data, true);
            console.log(formattedLaundryData);
            this.formatExportData(formattedLaundryData);
          } else {
            this.exporting = false;
          }
        })
        .catch((error) => {
          this.exporting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    formatExportData(data) {
      this.mixpanelTrack({
        module: "Order Fulfillment",
        action: "Export",
        value: "",
      });

      this.mixpanelTrack({
        event: `order_fulfillment_export`,
      });

      let title;
      if (this.period) {
        let period;
        const fPeriod = this.formatPeriodDate(this.period, "do m, y");
        if (this.period === 'custom') {
          const customFromDate = this.formatDate(this.customDate.from, "ddd do, m y");
          const customToDate = this.formatDate(this.customDate.to, "ddd do, m y");
          period = `${customFromDate} - ${customToDate}`;
        } else {
          period = fPeriod.start ? `${fPeriod.start} - ${fPeriod.end}` : fPeriod;
        }
        let updated = this.formatDateAndTime(this.dataTime, "do m, y");
        let date = this.formatDateAndTime(new Date(), "do m, y");
        title = `Orders Schedule - ${period}\nDownloaded at - ${updated}\nExported at - ${date}`;
      } else {
        const date = this.formatDate(this.$route.params.date, "ddd do, m y");
        title = `Orders Schedule - ${date}`;
      }

      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let formattedData = [];

      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key]),
      );
      headers = { ...headers, ...extra_headers };
      formattedData = this.formatExportDataByFields(data, headers);
      this.exportData = {
        title,
        headers,
        data: formattedData,
        fileName: "Order Schedule",
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
    formatExportDataByFields(data, headers) {
      return data.map((item, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "customer":
              export_object[key] = this.formatName(item.customer);
              break;
            case "order_id":
              export_object[key] = item.order_id;
              break;
            case "type":
              export_object[key] = item.type;
              break;
            case "order_type":
              export_object[key] = item.is_one_time_order
                ? "One-time"
                : "Subscription";
              break;
            case "customer_phone_number":
              export_object[key] = this.formatPhoneNumber(
                item.customer_phone_number,
              );
              break;
            case "locationarea":
              export_object[key] = item.locationarea;
              break;
            case "customer_address":
              export_object[key] = item.customer_address;
              break;
            case "landmark":
              export_object[key] = item.landmark;
              break;
            case "quantity":
              export_object[key] = item.quantity;
              break;
            case "item":
              export_object[key] = item.item;
              break;
            case "pickup":
              export_object[key] = this.formatDate(item.pickup, "y-m-d");
              break;
            case "sp_assigned":
              export_object[key] = item.sp_assigned.status
                ? `Assigned at ${this.formatTime(
                  item.sp_assigned.time,
                  "12h",
                )} by ${this.formatName(item.sp_assigned.name)}  `
                : "Pending";
              break;
            case "rider_enroute":
              export_object[key] = item.rider_enroute.status
                ? `En Route at ${this.formatTime(
                  item.rider_enroute.time,
                  "12h",
                )}  `
                : "Pending";
              break;
            case "pickup_rider_arrived":
              export_object[key] = item.pickup_rider_arrived.status
                ? `Arrived at ${this.formatTime(
                  item.pickup_rider_arrived.time,
                  "12h",
                )}  `
                : "Pending";
              break;
            case "rider_pickup":
              export_object[key] = item.rider_pickup.status
                ? `Picked up at ${this.formatTime(
                  item.rider_pickup.time,
                  "12h",
                )}  `
                : "Pending";
              break;
            case "pickup_rider_delivered":
              export_object[key] = item.pickup_rider_delivered.status
                ? `Delivered at ${this.formatTime(
                  item.pickup_rider_delivered.time,
                  "12h",
                )}  `
                : "Pending";
              break;
            case "pickup_qc":
              export_object[key] = item.pickup_qc.status
                ? `Quality Check at ${this.formatTime(
                  item.pickup_qc.time,
                  "12h",
                )} by ${this.formatName(item.pickup_qc.name)}  `
                : "Pending";
              break;
            case "sent_to_sp":
              export_object[key] = item.sent_to_sp.status
                ? `Sent to SP at ${this.formatTime(
                  item.sent_to_sp.time,
                  "12h",
                )}  `
                : "Pending";
              break;
            case "delivery_date":
              export_object[key] = this.formatDate(item.deliver_to_customer, "do m, y");
              break;
            case "order_date":
              export_object[key] = this.formatDate(item.created_at, "ddd do, m y");
              break;
            default:
              break;
          }
        });
        return export_object;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
