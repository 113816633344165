<template>
  <div>
    <span class="font-base mr-10"
      >{{ selectedOrders.length }}
      {{ formatServiceUnit("order", selectedOrders.length) }} selected</span
    >
    <el-dropdown :placement="'bottom'" @command="command">
      <el-button type="primary">
        Actions <i class="eden-icon-arrow-down"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="'assign-sp'" :disabled="!markReassignSp"
          >Reassign Sp
        </el-dropdown-item>
        <el-dropdown-item
          v-for="(value, key, i) in actions"
          :command="key"
          :key="i"
          :disabled="!markable[key]"
          >{{ value }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      ref="confirm"
      :show.sync="showMarkConfirmation"
      :title="actions[markFunction]"
      :button-text="'Mark'"
      :button-type="'success'"
      :button-status.sync="marking"
      @confirm="markOrders"
    >
      <p class="font-sm">
        Proceed to mark selected orders as
        <span class="text-bold text-grey-primary">{{
          formatToTitleCase(markFunction.replaceAll("_", " "))
        }}</span
        >?
      </p>
    </eden-confirm-dialog>
    <laundry-sp-assignment
      :show.sync="showSpAssignment"
      :selected-orders="selectedOrdersIds"
      :selected-orders-with-sp="selectedOrdersWithSp"
      @success="markOrders()"
    />
  </div>
</template>
<script>
import LaundrySpAssignment from "@/components/Orders/Schedule/Laundry/LaundrySpAssignment";
import schedule from "@/requests/orders/schedule";
export default {
  name: "LaundryPickupActions",
  components: { LaundrySpAssignment },
  props: {
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      markReassignSp: true,
      actions: {
        sp_assigned: "Mark Sp Assigned",
        rider_enroute: "Mark Rider En Route",
        pickup_rider_arrived: "Mark Rider Arrival",
        rider_pickup: "Mark Pick Up",
        pickup_rider_delivered: "Mark Delivered to Office",
        pickup_qc: "Mark Quality Check",
        sent_to_sp: "Mark Sent to SP",
      },
      markable: {
        pre_sp_assigned: true,
        sp_assigned: true,
        rider_enroute: true,
        pickup_rider_arrived: true,
        rider_pickup: true,
        pickup_rider_delivered: true,
        pickup_qc: true,
        sent_to_sp: true,
      },
      selectedOrdersIds: [],
      selectedOrdersWithSp: 0,
      selectedOrdersWithoutTally: 0,
      showSpAssignment: false,
      markFunction: "",
      marking: false,
      showMarkConfirmation: false,
    };
  },
  watch: {
    selectedOrders() {
      this.setSelectedOrders();
    },
  },
  created() {
    this.setSelectedOrders();
  },
  methods: {
    command(command) {
      if (command === "assign-sp") {
        this.markFunction = "sp_assigned";
        this.showSpAssignment = true;
      } else {
        this.markFunction = command;
        this.showMarkConfirmation = true;
      }
    },
    setSelectedOrders() {
      this.markReassignSp = true;
      this.selectedOrdersIds = [];
      this.selectedOrdersWithSp = 0;
      const markableEvents = Object.keys(this.markable).slice(1);
      markableEvents.forEach((key) => {
        this.markable[key] = true;
      });

      this.selectedOrders.forEach((order) => {
        markableEvents.forEach((key, index) => {
          const previousEvent =
            index < 1
              ? order.pre_sp_assigned.status
              : order[markableEvents[index - 1]].status;
          const currentEvent = !order[key].status;

          this.markable[key] =
            previousEvent && currentEvent && this.markable[key];

          if (key === "pickup_qc" && order.items.length === 0) {
            this.markable.pickup_qc = false;
            this.selectedOrdersWithoutTally++;
          }
        });

        this.markReassignSp =
          !order.rider_enroute.status && this.markReassignSp;
        this.selectedOrdersIds.push(order.order_id);
        if (order.sp !== "-") {
          this.selectedOrdersWithSp++;
        }
      });
    },
    markOrder() {
      if (!this.markable[this.markFunction]) {
        this.selectedOrdersWithoutTally > 0
          ? this.$message({
              type: "info",
              duration: 4000,
              message: "You need to add items tally before marking QC",
            })
          : this.$message.info("You need to mark previous event.");
        this.$emit("error");
        return;
      }
      this.markOrders();
    },
    markOrders() {
      this.marking = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: this.selectedOrdersIds,
        order_status: this.markFunction,
        event: "pickup",
      };

      schedule
        .activity("laundry", payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.showMarkConfirmation = false;
            this.$message.success(message);
            this.marking = false;
            this.$emit("updated");
          } else {
            this.$message.error(message);
            this.$emit("error");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$emit("error");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
